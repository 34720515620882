<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
			<v-select :id="id"
													hide-details
													:label="label"
													:class="classe"
													autocomplete="of"
													density="compact"
													:loading="loading"
													variant="outlined"
													:readonly="readonly"
													:disabled="disabled"
													v-model="selectedAux"
													clear-icon="mdi-close"
													:items="filteredItems"
													:clearable="clearable"
													:item-title="itemTitle"
													:item-value="itemValue"
													:return-object="returnObject"
													:menu-props="{closeOnContentClick: false}">
						<template v-slot:prepend-item>
									<div style="padding: 0px 10px;">
												<v-text-field data-lpignore="true" class="inp" density="compact" v-model="search" label="Pesquisar" hide-details
																										clearable prepend-inner-icon="mdi-magnify" placeholder="Pesquisar" single-line ></v-text-field>
									</div>
						</template>
						<template v-slot:item="{ item, props: { onClick } }">
									<v-list-item class="small-list-item" :title="item.title" @click="onClick"/>
						</template>
			</v-select>
</template>

<script>
   import _ from 'lodash'

   export default {
      props: {
         lista: {default: []},
         label: {default: 'Label'},
         id: {default: 'select'},
         itemTitle: {default: 'title'},
         classe: {default: ''},
         model: {default: null},
         itemValue: {default: null},
         loading: {default: true},
         clearable: {default: true},
         returnObject: {default: true},
         disabled: {default: false},
         readonly: {default: false},
      },
      data() {
         return {
            search: '',
            listaAux: [],
         }
      },
      methods: {
         validarCampo(campo) {
            if (campo !== undefined && campo !== null && campo !== '') {
               return true
            } else {
               return false
            }
         },
      },
      mounted() {
         // Adiconar esse coddigo no vue.config.js para não dar erro de "ResizeObserver loop"
         // devServer: {
         //    client: {
         //       overlay: {
         //          runtimeErrors: error => {
         //             const ignoreErrors = [
         //                'ResizeObserver loop completed with undelivered notifications.'
         //             ]
         //             return !ignoreErrors.includes(error.message)
         //          }
         //       }
         //    }
         // }
      },
      computed: {
         filteredItems() {
            if (this.listaAux.length > 0) {
               return this.listaAux
            } else {
               return this.validarCampo(this.search) ? [] : _.slice(this.lista, 0, 60)
            }
         },
         selectedAux: {
            get() {
               return this.model
            },
            set(v) {
               this.$emit('update', v)
               return this.selectedAux
            }
         },
      },
      watch: {
         search: function () {
            let exp = new RegExp(this.search.trim(), 'i');
            if (this.validarCampo(this.itemTitle)) {
               this.listaAux = _.slice(_.filter(this.lista, p => exp.test(p[this.itemTitle])), 0, 60)
            } else {
               this.listaAux = _.slice(_.filter(this.lista, p => exp.test(p)), 0, 60)
            }
         },
      },
   }
</script>
