/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getAuditoriaSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}


export const AuditoriaSCH = (perPage)=> {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = ['dt_log','hr_log','nome','tabela','rotina']
  filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
  return filtro
}
