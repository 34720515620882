/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class PrevisaoCaixa {
  constructor () {
    this.previsao_caixa_id = uuidV4()
    this.empresa_id = sessionStorage.getItem('empresa_id')
    this.data =''
    this.evento_caixa_id =''
    this.valor =0
    this.previsao_caixa_cc =[]

  }
}
