import {Filtro} from "../models/filtro/filtro";
export const  getBaseSCH = (campo, valor, condicao)=>{
  let filtro = new Filtro();
  filtro.perPage = 1;
  filtro.filters.addFilter(campo,valor,condicao);
  filtro.fields = ['*'];
  return filtro
};

export const BaseSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  return filtro
};

export const BaseDescSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  return filtro
};
export const BaseKeySCH = (perPage, key, direct) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  filtro.addOrder(key, direct)
  return filtro
};
