/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const UsuarioSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['nome', 'descricao', 'usuarios.grupo_acesso_id','empresa_id']
   filtro.join.addLeft('grupos_acesso', 'grupos_acesso.grupo_acesso_id', 'usuarios.grupo_acesso_id')
   filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
   return filtro
}
