/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Conta {
  constructor () {
    this.conta_id = uuidV4()
    this.descricao =''
    this.empresa_id = sessionStorage.getItem('empresa_id')

  }
}
