/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class EventoCaixa {
  constructor () {
    this.evento_caixa_id = uuidV4()
    this.descricao = ''
    this.tipo = 'C'
    this.empresa_id = sessionStorage.getItem('empresa_id')
    this.eventos_caixa_cc = []
  }
}
