import {Filtro} from "../models/filtro/filtro";

export const FornecedorSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
   filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
  return filtro
};

export const AllFornecedorSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['razao','fantasia','cnpj'];
   filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
  return filtro
};
