/* eslint-disable */
import {createStore} from 'vuex'
import geral from './modules/geral'
import token from "./modules/token";
import contas from "./modules/contas";
import usuarios from './modules/usuarios'
import empresas from "./modules/empresas";
import auditoria from "./modules/auditoria";
import configDash from "./modules/configDash";
import fornecedores from "./modules/fornecedores";
import centro_custos from "./modules/centro_custos";
import grupos_acessos from "./modules/grupos_acessos";
import eventos_caixas from "./modules/eventos_caixas";
import previsoes_caixa from "./modules/previsoes_caixa";
import caixas from "./modules/caixas";
import contas_pagar from "./modules/contas_pagar";

export default createStore({
   modules: {
      geral,
      token,
      caixas,
      contas,
      empresas,
      usuarios,
      auditoria,
      configDash,
      contas_pagar,
      fornecedores,
      centro_custos,
      grupos_acessos,
      eventos_caixas,
      previsoes_caixa,
   }
})
