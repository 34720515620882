/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class MovimentoCaixa {
  constructor (id) {
    this.movimento_caixa_id = uuidV4()
    this.caixa_id = id
    this.evento_caixa_id = ''
    this.data = ''
    this.valor = 0
    this.documento = ''
    this.complemento= ''
    this.conta_id = ''
    this.contas_pagar_parcela_id = ''
    this.fpagto = ''
    this.movimentos_caixa_cc = []
  }
}
