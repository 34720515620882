/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Caixa {
  constructor () {
    this.caixa_id = uuidV4()
    this.empresa_id = sessionStorage.getItem('empresa_id')
    this.data =''
    this.status =''
    this.observacao =''
    this.movimentos_caixa =[]


  }
}
