/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class ContaPagar {
  constructor () {
    this.conta_pagar_id = uuidV4()
    this.empresa_id = sessionStorage.getItem('empresa_id')
    this.fornecedor_id =''
    this.dt_emissao =''
    this.documento =''
    this.vl_total =0
    this.contas_pagar_cc =[]
    this.contas_pagar_parcelas =[]

  }
}
