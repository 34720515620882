/* eslint-disable */
import {pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {Empresa} from '../../models/empresa'
import {BaseSCH, getBaseSCH} from "../../search/BaseSCH";

const url = '/empresa'

const state = {
   empresa: new Empresa(),
   filtro: new Filtro()
}

const getters = {
   pegaEmpresa: (state) => {
      return state.empresa
   },
   pegaFiltro: (state) => {
      return state.filtro
   }
}

const actions = {
   async getItem({commit}, {empresa_id, onSucesso, onErro}) {
      try {
         var filtro = getBaseSCH('empresa_id', empresa_id, '=')
         const list = (await pegaDados(url + '/get', filtro)).data
         commit('SET_EMPRESA', list.data[0])
         onSucesso()
      } catch (error) {
         onErro()
         console.log(error)
      }
   },

   setFiltro({commit}, perPage) {
      commit('SET_FILTRO', BaseSCH(perPage))
   },

   async processarEmpresa({commit}, {acao, onSucesso, onErro}) {
      try {
         let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.empresa, acao)
         this.dispatch('geral/setMsgSuccess', res.data.message)
         onSucesso()
      } catch (error) {
         onErro()
         console.log('error: ', error)
         this.dispatch('geral/setObjMsgError', error.response.data)
      }
   },
}

const mutations = {
   SET_FILTRO(state, filtro) {
      state.filtro = filtro
   },
   SET_EMPRESA(state, empresa) {
      state.empresa = empresa
   },

}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
